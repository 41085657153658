@import 'public/styles/scss/_typo.scss';

.faq_sections {
  padding: 150px 0px;
}
.faq_sections_color {
  padding: 150px 0px;
  background: #fff4f3;
}

.faq_sections_inner {
  max-width: 890px;
  margin: 0px auto;
  width: 100%;
}
.faq_title {
  color: #304054;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.167%;
  letter-spacing: -1px;
}
.faq_wrap {
  width: 100%;
  margin-top: 70px;
}

.faq_listing {
  width: 100%;
  @include phone {
    padding: 0 15px;
  }
}
.faq_item_wrpr {
  position: relative;
  margin-bottom: 16px;
}
.faq_item {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #edcdca;
  background: transparent;
  position: relative;
}
.faq_item:last-child {
  border-bottom-width: 1px;
}

.faq_item_header {
  cursor: pointer;
  padding: 24px 0px;
  transition: all 0.3s ease;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.faq_item_text {
  color: #304054;
  font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.3px;
}

.faq_icon {
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: inline-block;
  transition: all 0.3s ease;
}
.faq_icon::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 20px;
  transition: all 0.3s ease;
  background-image: url('/images/shared/arrow-down.svg');
  background-repeat: no-repeat;
  transform: rotate(0deg);
  background-position: center;
}

.faqHeader_active {
  padding-bottom: 14px;
  transition: all 0.3s ease;
}

.faq_icon_active:before {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.faq_item_content {
  height: 0px;
  overflow: hidden;
  padding-left: 0px;
  padding-right: 24px;
  padding-bottom: 0px;
  transition: all 0.3s ease;
  color: #5b697b;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.faq_item_content_active {
  height: auto;
  padding-bottom: 24px;
  transition: all 0.3s ease;
  padding-top: 5px;
}

@media screen and (max-width: 1200px) {
  .faq_sections {
    padding: 90px 0px;
  }
}

@media screen and (max-width: 992px) {
  .faq_sections {
    padding: 70px 0px;
  }
  .faq_title {
    font-size: 28px;
  }
  .faq_item_text {
    font-size: 23px;
    line-height: 1.2;
  }
  .faq_item_content {
    font-size: 18px;
    line-height: 1.4;
  }
  .faq_wrap {
    padding: 50px 15px 0px;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .faq_sections {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 640px) {
  .faq_item_text {
    font-size: 18px;
    line-height: 1.2;
  }
  .faq_item_content {
    font-size: 16px;
    line-height: 1.4;
  }
  .faq_wrap {
    padding: 30px 0px 0px;
  }
}
